






















































import { Vue, Component } from 'vue-property-decorator';
import axios from 'axios';
import { apiBuilder } from '../../config';
import { namespace } from 'vuex-class';

const auth = namespace('auth');

@Component
export default class Login extends Vue {
	private rules = {
		required: (value: string | number) => !!value || 'Không Được Trống.',
	};

	private form = {
		username: '',
		password: '',
	};

	private btnLoading = false;

	@auth.Mutation
	public setUser!: (payload: any) => void; //eslint-disable-line

	public doLogin(): void {
		this.btnLoading = true;

		const apiUrl = apiBuilder('/account/login.php');

		const param = {
			params: {
				username: this.form.username,
				password: this.form.password,
			},
		};

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error;
				// console.log(error)

				this.btnLoading = false;

				if (error == '102' || error == '103') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Sai Tài Khoản Hoặc Mật Khẩu !',
					});
				} else if (error == '104') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Tài Khoản Bạn Đã Bị Khoá !',
					});
				} else if (error != '101') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error ! Please Reload !',
					});
				}

				const pl = {
					token: res.data.token,
					username: this.form.username,
				};

				this.setUser(pl);

				this.$swal({
					icon: 'success',
					title: 'Thành Công!',
					text: 'Đăng Nhập Thành Công!',
				});

				if (sessionStorage.getItem('redirectPath')) {
					//eslint-disable-next-line
					const path: any = sessionStorage.getItem('redirectPath');
					sessionStorage.removeItem('redirectPath');
					this.$router.push(path);
				} else {
					this.$router.push({ name: 'Home' });
				}
			})
			.catch((e) => {
				this.btnLoading = false;

				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});
	}
}
